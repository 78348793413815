<template>
  <docs-layout>
    <p>The component will return a stream of data of pre-defined format. Default to be PNG.</p>
    <div class="flex space-x-4">
      <div class="text-left">
        <p>Default pad:</p>
        <asom-signature-pad v-model="imageSrc" />
      </div>
      <div class="text-left">
        <p>Result image (PNG):</p>
        <img alt="" v-if="imageSrc" :src="imageSrc" />
      </div>
    </div>
  </docs-layout>
</template>
<script>
import DocsLayout from '@/docs/DocsLayout'
export default {
  components: {
    DocsLayout,
  },
  data() {
    return {
      imageSrc: null
    }
  }
}
</script>